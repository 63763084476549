import { formatDateToHHmm_or_MD_or_YYYYMD } from '@/utils/date/date';
import { Avatar, Box, Flex, Spacer, Stack, Text } from '@chakra-ui/react';
import { lazy } from 'react';

const WorkOrderCommentCard = lazy(() => import('./WorkOrderCommentCard'));
const ChatMessageCard = lazy(() => import('@/modules/chats/components/ChatMessageCard'));
const RequestCommentCard = lazy(() => import('@/modules/requests/components/RequestCommentCard'));
const PartCardWrapper = lazy(() => import('./PartCardWrapper'));

import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import WorkOrderCard from '@/components/workOrders/WorkOrderCard';
import { formatAssetName } from '@/modules/assets/utils';
import type { PartCard_PartFragment } from '@/modules/parts/components/PartCard.generated';
import RequestCard from '@/modules/requests/components/RequestCard';
import WorkOrderStatusLabel from '@/modules/workOrders/components/WorkOrderStatusLabel';
import useTranslation from '@/utils/i18n/useTranslation';
import type { INotificationEvent } from '../types';

type NotificationCardProps = {
  notificationEvent: INotificationEvent;
};

const systemAdminUserId = 'system-admin';

const NotificationEventCard = (props: NotificationCardProps) => {
  const {
    notificationEvent: {
      description,
      eventType,
      createdAt,
      createdBy: { id: userId, name: userName },
      request,
      requestComment,
      workOrder,
      workOrders,
      workOrderComment,
      chat,
      chatMessage,
      part,
      checkList,
    },
  } = props;

  const { t } = useTranslation();

  // TODO: Investigate why using router.push() affects the subsequent browser-back behavior
  // https://github.com/m2x-software/prototype-web/pull/495#discussion_r1374060341
  const handleClickRequestCard = (requestId: number) => {
    window.location.href = `/requests#${requestId}`;
  };

  const handleClickRequestCommentCard = (requestId: number, commentId: number) => {
    window.location.href = `/requests?commentId=${commentId}#${requestId}`;
  };

  const handleClickWorkOrderCard = (workOrderId: number) => {
    window.location.href = `/#${workOrderId}`;
  };

  const handleClickWorkOrderCommentCard = (workOrderId: number, commentId: number) => {
    window.location.href = `/?commentId=${commentId}#${workOrderId}`;
  };

  const handleClickChatMessageCard = (chatId: number, messageId: number) => {
    window.location.href = `/chats/?messageId=${messageId}#${chatId}`;
  };

  const handleClickPartCard = (partId: number) => {
    window.location.href = `/parts#${partId}`;
  };

  return (
    <Flex p={2} w='100%' bgColor='white'>
      <Avatar
        size='sm'
        mr='2'
        fontWeight='bold'
        name={userName}
        {...(userId === systemAdminUserId && {
          src: '/push.png',
          showBorder: true,
          borderColor: 'primary.500',
        })}
      />
      <Stack flex='1' w='0'>
        <Flex justifyContent='space-between'>
          <Text color='neutral.800'>{description}</Text>
          <Text color='neutral.500' ml={1}>
            {formatDateToHHmm_or_MD_or_YYYYMD(createdAt)}
          </Text>
        </Flex>
        {eventType === 'request' && request && (
          <RequestCard
            {...request}
            onCardClicked={() => {
              handleClickRequestCard(request.id);
            }}
          />
        )}
        {eventType === 'requestComment' && request && requestComment && (
          <SuspenseWithSpinner>
            <RequestCommentCard
              request={request}
              requestComment={requestComment}
              onClick={() => {
                handleClickRequestCommentCard(request.id, requestComment.id);
              }}
            />
          </SuspenseWithSpinner>
        )}
        {eventType === 'workOrder' && workOrder && (
          <WorkOrderCard
            {...workOrder}
            onWorkOrderClicked={() => {
              handleClickWorkOrderCard(workOrder.id);
            }}
          />
        )}
        {eventType === 'workOrderComment' && workOrder && workOrderComment && (
          <SuspenseWithSpinner>
            <WorkOrderCommentCard
              workOrder={workOrder}
              workOrderComment={workOrderComment}
              onClick={() => {
                handleClickWorkOrderCommentCard(workOrder.id, workOrderComment.id);
              }}
            />
          </SuspenseWithSpinner>
        )}
        {eventType === 'chatMessage' && chat && chatMessage && (
          <SuspenseWithSpinner>
            <ChatMessageCard
              chat={chat}
              chatMessage={chatMessage}
              onClick={() => {
                handleClickChatMessageCard(chat.id, chatMessage.id);
              }}
            />
          </SuspenseWithSpinner>
        )}
        {eventType === 'part' && part && (
          <SuspenseWithSpinner>
            <PartCardWrapper
              part={part as unknown as PartCard_PartFragment}
              onClick={() => {
                handleClickPartCard(part.id);
              }}
            />
          </SuspenseWithSpinner>
        )}
        {eventType === 'workOrderDueDate' &&
          workOrders &&
          workOrders.map((workOrder) => (
            <Box
              p={2}
              _hover={{
                bg: 'neutral.50',
                cursor: 'pointer',
              }}
              borderWidth={1}
              onClick={() => handleClickWorkOrderCard(workOrder.id)}
              key={workOrder.id}
            >
              <Stack align='stretch' textAlign='left'>
                <Flex>
                  <Text
                    color='neutral.800'
                    {...(workOrder.title && { fontSize: 'lg', letterSpacing: 'wide' })}
                  >
                    {workOrder.title || formatAssetName(workOrder.asset) || t('pages.task')}
                  </Text>
                  <Spacer />
                  <WorkOrderStatusLabel status={workOrder.status} />
                </Flex>
              </Stack>
            </Box>
          ))}

        {eventType === 'checkListAlertTriggered' && checkList?.workOrder && (
          <WorkOrderCard
            {...checkList.workOrder}
            onWorkOrderClicked={() => {
              handleClickWorkOrderCard(checkList.workOrder!.id);
            }}
          />
        )}
      </Stack>
    </Flex>
  );
};

export default NotificationEventCard;
