import { useCallback, useEffect, useState } from 'react';
import { useToast } from '../atoms/toast';
import useTranslation from '../i18n/useTranslation';

export const useNetworkStatus = () => {
  const [isNetworkConnected, setIsNetworkConnected] = useState(true);
  const { toast } = useToast();
  const { t_toasts } = useTranslation();

  const handleNetworkStatusChange = useCallback(() => {
    // https://developer.mozilla.org/en-US/docs/Web/API/NavigatorOnLine/onLine
    if (navigator.onLine) {
      setIsNetworkConnected(true);

      return toast({
        title: t_toasts('success.internet-connected'),
        status: 'success',
        duration: 5000,
        isClosable: true,
        isAlwaysShow: true,
      });
    }

    setIsNetworkConnected(false);
  }, [t_toasts, toast]);

  useEffect(() => {
    window.addEventListener('online', handleNetworkStatusChange);
    window.addEventListener('offline', handleNetworkStatusChange);

    return () => {
      window.removeEventListener('online', handleNetworkStatusChange);
      window.removeEventListener('offline', handleNetworkStatusChange);
    };
  }, [handleNetworkStatusChange]);

  return { isNetworkConnected };
};
